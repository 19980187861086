<template>
  <div id="faqs" class="mb-5">
    <b-container>
      <div class="faq-wrapper">
        <h1 class="faq-titles">Perguntas frequentes</h1>

        <VueFaqAccordion :items="myItems" class="faq-div" activeColor="#E04A36" />
      </div>
    </b-container>
  </div>
</template>

<script>
import VueFaqAccordion from "vue-faq-accordion";

export default {
  components: {
    VueFaqAccordion,
  },
  data() {
    return {
      myItems: [
          {
          title: "Não consigo assistir as palestras na hora que acontecem. Tem outro horário disponível?",
          value: "Sim, as palestras acontecem no horário marcado, mas ficarão disponíveis para acesso até 03/10/2021. Sendo assim, você terá mais tempo para ver e rever todo o conteúdo e não perder nada! O Congresso Abravet está disponibilizando também um Acesso PREMIUM, com maior numero de palestras e outros conteúdos que ficarão disponíveis também até 03/10/2021, assim você poderá ver e rever quantas vezes quiser neste período!",
          //   category: 'Tab-2'
        },
        {
          title:
            "Para a submissão de resumos, somente são aceitos relatos de caso?",
          value:
            "Para a submissão de resumos aceitaremos além de relatos de caso, revisões de literatura e artigos científicos.",
          // category: 'Tab-1'
        },
        {
          title:
            "O Certificado de Participação vale como horas complementares na graduação?",
          value:
            "O Certificado de Participação emitido no Congresso da Abravet é completo. Possuirá seu nome completo, nome do evento, CNPJ, realização, carga horária, data, programação (no verso) e assinatura. Porém, para comprovar as horas complementares na graduação, verifique se a sua intituição de ensino tem esta política antes de realizar o pedido.",
          // category: 'Tab-2'
        },
        {
          title: "O Congresso Abravet terá submissão de artigos ou resumos?",
          value: "Sim, todos os participantes estão convidados a submeter trabalhos, conforme normas descritas no site. Os melhores serão convidados a apresentar seus trabalhos na Revista Brasileira de Acupuntura Veterinária.",
          //   category: 'Tab-2'
        },{
          title: "Os resumos aprovados terão certificado e ISBN?",
          value: "Sim, os resumos aprovados tem direito a Certificado de publicação, porém é necessário que o autor principal esteja inscrito no Congresso, isso também garante que o trabalho receberá o código ISBN.",
          //   category: 'Tab-2'
        },{
          title: "Quando e onde posso acessar o meu Certificado?",
          value: "A emissão do Certificado estarão disponíveis após 70% de visualização do conteúdo. O certificado poderá ser gerado na nossa plataforma exclusiva para membros e todos os detalhes serão enviados por e-mail.  ",
          //   category: 'Tab-2'
        },{
          title: "Quanto custa para participar do Congresso Abravet?",
          value: "Realize a sua inscrição, para participar e assistir todo o conteúdo do evento. Abaixo seguem os valores de acordo com o conteúdo e se é associado ou não da Abravet. • Acesso Premium (todo o conteúdo: Congresso + Webinário + Simpósio Internacional + Workshop + mesas redondas) - R$ 480,00 • Acesso Premium para Associados (todo o conteúdo: Congresso + Webinário + Simpósio Internacional + Workshop + mesas redondas) - R$ 280,00 com cupom de desconto • Acesso Básico (apenas Congresso) - R$ 280,00 • Acesso Básico para Associados (apenas Congresso) - R$ 150,00 com cupom de desconto   Associado, solicite o seu cupom de desconto enviando e-mail para secretaria@abravet.com.br",
          //   category: 'Tab-2'
        },{
          title: "Associados da APAMV (Associação Portuguesa de Acupuntura Médico-Veterinária) tem desconto na inscrição?",
          value: "Sim, os associados da APAMV possuem o mesmo desconto na inscrição que os associados da Abravet. Solicite seu cupom de desconto enviando e-mail com seus dados para secretaria@abravet.com.br",
          //   category: 'Tab-2'
        },{
          title: "Atualmente não sou associado(a) da Abravet. Se eu me associar agora terei direito ao desconto na inscrição do Congresso?",
          value: "Sim! Quem ainda não é associado(a) da Abravet poderá se associar para obter o desconto na inscrição. Para isso siga os passos abaixo: 1) Acesse o nosso tutorial de como se associar neste link: https://drive.google.com/file/d/1tHAaKDa8OkuxjWm6pynK6jj_0xpwgpVG/view 2) Após se cadastrar, pague a anuidade 2021; 3) Aguarde a sua admissão como associado(a) após a confirmação do pagamento (este processo pode demorar até 7 dias). 4) Após a sua admissão, solicite o cupom de desconto para associados pelo e-mail secretaria@abravet.com.br e realize a sua inscrição com o valor de associado(a).",
          //   category: 'Tab-2'
        },

        // Tem sim, para isso, é necessário se inscrever no evento. Após a inscrição o certificado será emitido após 70% de visualização do conteúdo.

        // O Certificado de Participação vale como horas complementares na graduação?
        // O Congresso Abravet terá submissão de artigos ou resumos?
        // Os resumos aprovados terão certificado e ISBN?
        // Quando e onde posso acessar o meu Certificado?
        // Quanto custa para participar do Congresso Abravet?
        // Associados da APAMV (Associação Portuguesa de Acupuntura Médico-Veterinária) tem desconto na inscrição?
        // Atualmente não sou associado(a) da Abravet. Se eu me associar agora terei direito ao desconto na inscrição do Congresso?
        // Estudantes de Medicina Veterinária possuem desconto na inscrição do Congresso?
        // Não consigo assistir as palestras na hora que acontecem. Tem outro horário disponível?
        // Como funciona o Congresso Abravet?
      ],
    };
  },
};
</script>
<style >
.faq-wrapper {
  background-color: #f4f8fb;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  padding-bottom: 60px;

}
.faq-div {
  display: flex;
  justify-content: center;
}
.accordion {
  width: 100%;
  background-color: #fff;
}
.accordion__item {
  /* background-color: blue; */
}
.faq-titles {
  text-align: center;
  padding: 30px;
  display: inline-block;
  position: relative;
  margin: 0 auto;
  margin-bottom: 50px;
}
.faq-titles::after {
  position: absolute;
  content: " ";
  bottom: 0;
  left: 25%;
  width: 50%;
  height: 2px;
  background-color: #ccc;
}
</style>